const BASE_URL_V1 = "/api/v1";

const API_URL = {
  LOGIN: BASE_URL_V1 + "/login",
  LOGOUT: BASE_URL_V1 + "/logout",
  BRANCH_CODE: BASE_URL_V1 + "/branch_code",
  INQUIRIES: {
    INDEX: BASE_URL_V1 + "/inquiries",
    UPLOAD: BASE_URL_V1 + "/inquiries/upload"
  },
  CUSTOMERS: {
    INDEX: BASE_URL_V1 + "/customers",
    GET: BASE_URL_V1 + "/customers/:customer_id",
    GET_CURRENT: BASE_URL_V1 + "/current_customer/get_current_customer",
    QUESTION_ANSWERS: BASE_URL_V1 + "/customers/:customer_id/question_answers",
    GET_TAGS: BASE_URL_V1 + "/customer_tags",
    TAG_BATCH_ADD: BASE_URL_V1 + "/customer_tags/batch_create",
    UPDATE_CURRENT_CUSTOMER: BASE_URL_V1 + "/current_customer",
    LIST: BASE_URL_V1 + "/customers/list",
  },
  LINE_CUSTOMER: {
    INDEX: BASE_URL_V1 + "/line_customers"
  },
  PEOFILE: {
    INDEX: BASE_URL_V1 + "/profile"
  },
  ACCOUNT: {
    INDEX: BASE_URL_V1 + "/users/company",
    LIST: BASE_URL_V1 + "/users/company/list",
    GET: BASE_URL_V1 + "/users/company/:account_id"
  },
  BRANCH: {
    INDEX: BASE_URL_V1 + "/branches",
    GET: BASE_URL_V1 + "/branches/:branch_id"
  },
  CUSTOMER_BRANCH: {
    GET_BY_CODE: BASE_URL_V1 + "/customer_branches/by_code/:code"
  },
  MENU_CATEGORY: {
    INDEX: BASE_URL_V1 + "/menu_categories",
    GET: BASE_URL_V1 + "/menu_categories/:menu_category_id",
    MENU: {
      INDEX: BASE_URL_V1 + "/menu_categories/:menu_category_id/menu_products",
      DELETE: BASE_URL_V1 + "/menu_products/:menu_product_id",
      GET: BASE_URL_V1 + "/menu_products/:menu_product_id"
    }
  },
  QUESTION_THEME: {
    INDEX: BASE_URL_V1 + "/question_themes",
    GET: BASE_URL_V1 + "/question_themes/:id",
    CREATE: BASE_URL_V1 + "/question_themes",
    UPDATE: BASE_URL_V1 + "/question_themes/:id",
    DELETE: BASE_URL_V1 + "/question_themes/:id"
  },
  CUSTOMER_QUESTION_THEME: {
    INDEX: BASE_URL_V1 + "/customer_question_themes",
    SHOW: BASE_URL_V1 + "/customer_question_themes/:id"
  },
  CUSTOMER_TAG_PRODUCTS: {
    INDEX: BASE_URL_V1 + "/customer_tag_products"
  },
  LINE_CUSTOMER_TAG_PRODUCTS: {
    INDEX: BASE_URL_V1 + "/line_customer_tag_products"
  },
  QUESTION_RESERVATION: {
    CREATE: BASE_URL_V1 + "/question_theme_reservations"
  },
  QUESTION_CATEGORY: {
    INDEX: BASE_URL_V1 + "/question_themes/:id/question_categories",
    GET: BASE_URL_V1 + "/question_categories/:id",
    CREATE: BASE_URL_V1 + "/question_themes/:id/question_categories",
    UPDATE: BASE_URL_V1 + "/question_categories/:id",
    DELETE: BASE_URL_V1 + "/question_categories/:id"
  },
  QUESTION_PRODUCT: {
    INDEX: BASE_URL_V1 + "/question_categories/:id/question_products",
    GET: BASE_URL_V1 + "/question_products/:id",
    CREATE: BASE_URL_V1 + "/question_categories/:id/question_products"
  },
  ANSWER: {
    BATCH_CREATE: BASE_URL_V1 + "/answers/batch",
    BATCH_CREATE_IMAGE: BASE_URL_V1 + "/answers/batch_with_image"
  },
  CUSTOMER_ANSWER: {
    CREATE: BASE_URL_V1 + "/customer_answers"
  },
  MENU: {
    INDEX: BASE_URL_V1 + "/menu_categories/:menu_category_id/menu_products"
  },
  COMPANIES: {
    INDEX: BASE_URL_V1 + "/companies",
    SALON_INFO: BASE_URL_V1 + "/companies/current"
  },
  FORGOT_PASSWORD: {
    INDEX: BASE_URL_V1 + "/reset_password"
  },
  UPDATE_PASSWORD: {
    INDEX: BASE_URL_V1 + "/update_password"
  },
  CUSTOMER_ECHART: {
    INDEX: BASE_URL_V1 + "/customer_echarts",
    DELETE_IMAGE: BASE_URL_V1 + "/attachments"
  },
  FACE_ANALYSIS: {
    CREATE: BASE_URL_V1 + "/scan_results",
    SCAN_RESULTS: BASE_URL_V1 + "/scan_results/ai"
  },
  SALON_PROPOSAL: {
    INDEX: BASE_URL_V1 + "/salon_proposals"
  },
  TAGS: {
    GET: BASE_URL_V1 + "/tag_categories",
    TAG_CATEGORY: BASE_URL_V1 + "/tag_categories/:tag_category_id",
    TAG_PRODUCT: BASE_URL_V1 + "/tag_categories/:tag_category_id/tag_products",
    TAG_PRODUCT_GET: BASE_URL_V1 + "/tag_products/:id",
    TAG_ANALYSIS: BASE_URL_V1 + "/tag_analysis"
  },
  TAG_PRODUCT: {
    INDEX: BASE_URL_V1 + "/tag_products",
    SEARCH: BASE_URL_V1 + "/tag_product_search/by_category_name"
  },
  USER: {
    INDEX: BASE_URL_V1 + "/user",
    CHANGE_CURRENT_BRANCH: BASE_URL_V1 + "/user/change_current_branch"
  },
  USERS: {
    INDEX: BASE_URL_V1 + "/users",
    GET: BASE_URL_V1 + "/users/:user_id"
  },
  CUSTOMER_TREATMENT_EXAMPLE: {
    INDEX: BASE_URL_V1 + "/customer_treatment_examples"
  },
  TREATMENT_EXAMPLE: {
    INDEX: BASE_URL_V1 + "/treatment_examples",
    GET: BASE_URL_V1 + "/treatment_examples/:id",
    DELETE: BASE_URL_V1 + "/treatment_examples/:id",
    RESERVATION: BASE_URL_V1 + "/treatment_examples/reservation",
  },
  RESERVATION: {
    CREATE: BASE_URL_V1 + "/reservations",
    GET: BASE_URL_V1 + "/reservations",
    DELETE: BASE_URL_V1 + "/reservations",
    AFTER_FOLLOW_ADD: BASE_URL_V1 + "/after_reservation_follows",
    ADD: BASE_URL_V1 + "/reservations",
    CREATE_BY_LATEST: BASE_URL_V1 + "/reservations/create_by_latest",
    CREATE_BY_DATE: BASE_URL_V1 + "/reservations/create_by_date",
  },
  FACE_PROPOSAL: {
    GET: BASE_URL_V1 + "/face_proposal_types"
  },
  BRANCH_ANALYSIS: {
    GET_STATISTICS_DATA: BASE_URL_V1 + "/kpi_analysis"
  },
  BRANCH_USERS: {
    GET_BRANCH_USERS: BASE_URL_V1 + "/branch_users"
  },
  MESSAGE: {
    LIST: BASE_URL_V1 + "/messages",
    CREATE:  BASE_URL_V1 + "/messages",
  }
};
export { API_URL };
