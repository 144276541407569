import { ROUTE } from "constants/paths";
import MenuHeader from "layouts/MainLayout/MenuHeader";
import InfoTab from "pages/Customer/components/InfoTab";
import MyProposalContent from "pages/CustomerPages/components/MyProposalContent";
import ShareProposal from "pages/CustomerPages/components/ShareProposal";
import StoreInformation from "pages/CustomerPages/components/StoreInformation"
import { useState } from "react";
import { useParams } from "react-router-dom";

import Container from "components/Container";
import { useReservationQuery } from "api/useReservation";

const MyProposal: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { branchCode, reservationId } = useParams();
  const { data: reservation } = useReservationQuery(reservationId || "1");

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <>
      <Container className="pb-0">
        <MenuHeader
          headerLabel={"マイカルテ"}
          leftLabel={"戻る"}
          leftLink={ROUTE.CUSTOMER_MYPAGE.replace(
            ":branchCode",
            branchCode || ""
          )}
        />
        <InfoTab
          onTabClick={handleTabClick}
          activeTab={activeTab}
          tab1="プレカルテ"
          tab2="共有されたカルテ"
          tab3="店舗情報"
        />
        {activeTab === 0 && <MyProposalContent />}
        {activeTab === 1 && <ShareProposal />}
      </Container>
      {activeTab === 2 && <StoreInformation branchId={reservation?.reservation?.branch_id}/>}
    </>
  );
};

export default MyProposal;
