import {useMutation, useQuery} from "@tanstack/react-query";
import {ROUTE} from "constants/paths";
import {NotifyTypeEnum} from "enums/notify";
import {useNavigate} from "react-router";
import {IAddCustomer, ICustomerList, IUpdateCustomer} from "types/customer";
import {notify} from "utils/notify";

import {deleteApi, getApi, patchApi, postApi} from "./common/api";
import {API_URL} from "./common/path";

export const useCustomerListQuery = (params: Object = {}) => {
  return useQuery(
    ["get_customers", params],
    async () => {
      try {
        const {data} = await getApi(API_URL.CUSTOMERS.INDEX, params);
        return data.customers.map((customer: ICustomerList) => ({
          id: customer.id,
          name: customer.last_name + " " + customer.first_name,
          imagePath: customer.image_path,
          avatar_path: customer.avatar_path,
          reservationDate: customer.with_reservations?.reservation_date ?? "",
          phoneNumber: customer.phone_number,
          users: customer.with_reservations?.users ?? []
        }));
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useCustomerQuery = (customerId: string) => {
  const API_GET_PATH = API_URL.CUSTOMERS.GET;

  return useQuery(
    ["get_user", customerId],
    async () => {
      if (!customerId) {
        return null;
      }
      try {
        const {data} = await getApi(
          API_GET_PATH.replace(":customer_id", customerId || ""),
          {}
        );
        return data;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useGetCurrentCustomerQuery = () => {
  const API_GET_PATH = API_URL.CUSTOMERS.GET_CURRENT;

  return useQuery(
    ["get_current_customer"],
    async () => {
      try {
        const {data} = await getApi(API_GET_PATH, {});
        return data.customer;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useAddCustomer = () => {
  return useMutation(
    (newCustomer: IAddCustomer) => {
      return postApi(API_URL.CUSTOMERS.INDEX, newCustomer);
    },
    {
      onSuccess: () => {
        notify("お客様情報が追加されました", NotifyTypeEnum.SUCCESS);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useDeleteCustomer = (id: string) => {
  const navigate = useNavigate();
  const deleteCustomer = useMutation(
    (id: string) => {
      return deleteApi(`${API_URL.CUSTOMERS.INDEX}/${id}`, {});
    },
    {
      onSuccess: () => {
        notify("お客様のデータが削除されました", NotifyTypeEnum.SUCCESS);

        setTimeout(() => {
          navigate("/customer");
        }, 1000);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
  return deleteCustomer;
};

export const useUpdateNoteCustomer = (id: string | number) => {
  const API_CREATE_NOTE = API_URL.CUSTOMERS.GET;
  return useMutation(
    (data: IUpdateCustomer) => {
      return patchApi(API_CREATE_NOTE.replace(":customer_id", id?.toString()), data);
    },
    {
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useUpdateCustomer = () => {
  return useMutation(
    (data: IUpdateCustomer) => {
      return patchApi(API_URL.CUSTOMERS.UPDATE_CURRENT_CUSTOMER, data);
    },
    {
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useAddLineCustomer = () => {
  return useMutation(
    (newCustomer: IAddCustomer) => {
      return postApi(API_URL.LINE_CUSTOMER.INDEX, newCustomer);
    },
    {
      onSuccess: () => {
        // notify("お客様情報が追加されました", NotifyTypeEnum.SUCCESS);
        // navigate(`${ROUTE.CUSTOMER.INDEX}`);
      },
      onError: (error: any) => {
        // notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useCustomerQuestionAnswersQuery = (
  customer_id: string,
  question_theme_id: string,
  reservation_id: string | number
) => {
  const API_GET_PATH = `${API_URL.CUSTOMERS.QUESTION_ANSWERS.replace(
    ":customer_id",
    String(customer_id)
  )}?question_theme_id=${question_theme_id}&reservation_id=${reservation_id}`;
  return useQuery(
    ["get_customer_question_answers", customer_id, question_theme_id],
    async () => {
      try {
        const {data} = await getApi(API_GET_PATH, {});
        return data;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useDeleteCustomerQuestions = (id: string) => {
  const navigate = useNavigate();

  const deleteCustomerQuestionAnswers = useMutation(
    (res_id: string) => {
      return deleteApi(`${API_URL.RESERVATION.DELETE}/${res_id}`, {});
    },
    {
      onSuccess: () => {
        notify("カルテが削除されました", NotifyTypeEnum.SUCCESS);

        setTimeout(() => {
          window.location.href = ROUTE.CUSTOMER.CUSTOMER_INFO.replace(
            ":id",
            id.toString()
          );
        }, 1000);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );

  return deleteCustomerQuestionAnswers;
};
