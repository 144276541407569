import axios from "axios";
import { Interface } from "readline";
import { isKatakana, toKatakana as wanakana } from "wanakana";

import { LOCAL_STORAGE_KEY } from "../constants/common";
import { IUserInfo } from "../types/user";

const URL_CONVERT_KATAKANA = "https://dc3i1t2n86q86.cloudfront.net/toKatakana";

export const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) || null;
};

export const isLoggedIn = () => {
  return (
    localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) ||
    localStorage.getItem(LOCAL_STORAGE_KEY.USER_ID) ||
    null
  );
};

export const getUserId = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.USER_ID) || null;
};

export const getBranchIds = () => {
  const userInfoString: string | null = localStorage.getItem(
    LOCAL_STORAGE_KEY.USER_INFO
  );
  const userInfo: IUserInfo | null = userInfoString
    ? JSON.parse(userInfoString)
    : null;
  return userInfo?.branch_ids || [];
};

export const toConvertKatakana = async (text: string) => {
  try {
    const response = await axios.post(
      URL_CONVERT_KATAKANA,
      { message: text },
      {}
    );
    const res = response?.data?.res;
    if (!isKatakana(res)) {
      return wanakana(res);
    }
    return res;
  } catch (e) {
    return wanakana(text);
  }
};

export const formatCurrency = (number: number) => {
  return new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
    minimumFractionDigits: 0
  }).format(number);
};

export const convertToCamelCase = (str: string) => {
  return str.replace(/_./g, (match) => match.charAt(1).toUpperCase());
};

export const generateGender = (gender: string) => {
  switch (gender) {
    case "male":
      return "男性";
    case "female":
      return "女性";
  }
};

export const normalizeZipCode = (zipCode: string, lengthAllowed: number) => {
  const zipCodeWhenRemoveCharacter = zipCode.replace(/\D/g, "");
  return zipCodeWhenRemoveCharacter.slice(0, lengthAllowed);
};

export const shortenString = (str: string, length: number) => {
  if (str.length > length) {
    return str.substring(0, length);
  } else {
    return str;
  }
};

export const getTimeOpts = (maxHour = 24, minuteInterval = 15) => {
  const TIME_OPTIONS = [];

  for (let hours = 0; hours < maxHour; hours++) {
    for (let minutes = 0; minutes < 60; minutes += minuteInterval) {
      const formattedHours12 = (hours % 12 || 12).toString();
      const formattedHours24 = hours.toString().padStart(2, "0");

      const formattedMinutes = minutes.toString().padStart(2, "0");

      const label12 = `${
        hours >= 12 ? "午後" : "午前"
      }${formattedHours12}:${formattedMinutes}`;
      const value = `${formattedHours24}:${formattedMinutes}`;

      TIME_OPTIONS.push({ label: label12, value });
    }
  }

  return TIME_OPTIONS
}
